import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    hr: {
        zalagaonica: 'OTKUP.HR',
        verzija: 'v2',
        login: {
            korisnickoIme: 'Korisničko ime',
            lozinka: 'Lozinka',
            poslovnica: 'Poslovnica',
            prijava: 'Prijava',
            odjava: 'Odjava',
            odjavaPotvrda: 'Jeste li sigurni da se želite odjaviti iz sustava?'
        },
        starting: {
            rezervacija: 'Rezervacija',
            otkup: 'Otkup',
            primka: 'Primka',
            prodaja: 'Prodaja',
            procjena: 'Procjena',
            radniNalog: 'Radni nalog',
            meduskladisnica: 'Međuskladišnica',
            dokumenti: 'Dokumenti'
        },
        artikl: {
            artikl: 'Artikl',
            sifra: 'Šifra artikla',
            naziv: 'Naziv artikla',
            mjernaJedinica: 'Mjerna jedinica',
            kategorija: 'Kategorija artikla',
            potkategorija: 'Potkategorija artikla',
            serijskiBroj: 'Serijski broj',
            serijskiBrojPlaceholder: 'IMEI, broj šasije, S/N...',
            opis: 'Opis artikla',
            spremiKaoNoviArtikl: 'Spremi kao novi artikl'
        },
        usluga: {
            usluga: 'Usluga',
            sifra: 'Šifra usluge',
            naziv: 'Naziv usluge',
            mjernaJedinica: 'Mjerna jedinica',
            jedinicnaCijena: 'Jedinična cijena'
        },
        klijent: {
            klijent: 'Klijent',
            drzava: 'Država',
            adresa: 'Adresa',
            postanskiBrojNaselje: 'Poštanski broj i naselje',
            kontaktBroj: 'Kontakt broj',
            kontaktBrojPlaceholder: 'Kontakt broj (npr. 0911234567)',
            kontaktEmail: 'Kontakt email',
            tipKlijenta: 'Tip klijenta',
            fizickaOsoba: 'Fizička osoba',
            pravnaOsoba: 'Pravna osoba',
            ime: 'Ime',
            prezime: 'Prezime',
            tipIdentifikacijskogBroja: 'Tip identifikacijskog broja',
            identifikacijskiBroj: 'Identifikacijski broj',
            oib: 'OIB',
            brojOsobne: 'Broj osobne iskaznice',
            brojPutovnice: 'Broj putovnice',
            datumRodenja: 'Datum rođenja',
            datumRodenjaPlaceholder: 'Datum rođenja (npr. dd.mm.gggg.)',
            naziv: 'Naziv',
            kontaktOsoba: 'Kontakt osoba',
            napomenaOpreza: 'Napomena opreza',
            napomenaOprezaModal: 'Klijent koji je odabran ima napomenu opreza u sustavu'
        },
        rezervacija: {
            rezervacija: 'Rezervacija',
            novaRezervacija: 'Nova rezervacija',
            importRezervacije: 'Import rezervacije',
            napraviRezervaciju: 'Napravi rezervaciju',
            sluzbenaNapomenaRezervacije: 'Službena napomena rezervacije - vidljiva na potvrdi rezervacije',
            nesluzbenaNapomenaRezervacije: 'Neslužbena napomena rezervacije - vidljiva samo interno',
            potvrdaRezervacije: 'Potvrda rezervacije',
            pitanjePotvrdeRezervacije: 'Jeste li sigurni da želite napraviti ovu rezervaciju?',
            poslovnica: 'Poslovnica',
            datumIsteka: 'Datum isteka',
            jedinicnaProdajnaCijena: 'Jedinična prodajna cijena',
            ukupnaCijenaPodizanjaRezervacije: 'Ukupna cijena podizanja rezervacije',
            fiksnaCijena: 'Fiksna cijena',
            postotak: 'Postotak',
            obveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - cijena rezervacije različita od predefinirane!',
            ucitavanjeSlika: 'Učitavanje slika artikala rezervacije',
            skladisnaJedinka: 'Skladišna jedinka',
            cijenaNijeDjeljiva: 'Navedena cijena nije djeljiva s količinom artikala, izaberite jednu od ponuđenih cijena',
            prvaNizaCijenaPodizanja: 'Prva niža cijena podizanja rezervacije',
            prvaVisaCijenaPodizanja: 'Prva viša cijena podizanja rezervacije'
        },
        otkup: {
            noviOtkup: 'Novi otkup',
            novaPrimka: 'Nova primka',
            noviPredmet: 'Novi predmet',
            jedinicnaCijenaOtkupa: 'Jedinična cijena otkupa',
            brojKupovnogRacuna: 'Broj kupovnog računa',
            datumIVrijemeKupovnogRacuna: 'Datum i vrijeme kupovnog računa',
            napomenaUzArtikl: 'Napomena uz artikl',
            dodajNapomenuUzArtikl: 'Dodaj napomenu uz artikl',
            sluzbenaNapomenaOtkupa: 'Službena napomena otkupa - vidljiva na ugovoru otkupa',
            sluzbenaNapomenaPrimke: 'Službena napomena primke - vidljiva na primki',
            nesluzbenaNapomenaOtkupa: 'Neslužbena napomena otkupa - vidljiva samo interno',
            nesluzbenaNapomenaPrimke: 'Neslužbena napomena primke - vidljiva samo interno',
            potvrdaOtkupa: 'Potvrda otkupa',
            potvrdaPrimke: 'Potvrda primke',
            napraviOtkup: 'Napravi otkup',
            napraviPrimku: 'Napravi primku',
            pitanjePotvrdeOtkupa: 'Jeste li sigurni da želite napraviti ovaj otkup?',
            pitanjePotvrdePrimke: 'Jeste li sigurni da želite napraviti ovu primku?',
            predlaganjeProdajnihCijena: 'Predlaganje prodajnih cijena',
            predloziProdajneCijene: 'Predloži prodajne cijene',
            prijedlogProdajneCijene: 'Prijedlog prodajne cijene'
        },
        procjena: {
            novaProcjena: 'Nova procjena',
            potvrdaZahtjevaZaProcjenu: 'Potvrda zahtjeva za procjenu',
            pitanjePotvrdeZahtjevaZaProcjenu: 'Jeste li sigurni da želite napraviti ovaj zahtjev za procjenu?',
            napraviZahtjevaZaProcjenu: 'Napravi zahtjev za procjenu',
            ocekivanaJedinicnaVrijednost: 'Očekivana jedinična vrijednost',
            ucitavanjeSlika: 'Učitavanje slika artikala procjene',
            sluzbenaNapomenaProcjena: 'Službena napomena procjene - vidljiva na Zahtjevu za procjenom',
            nesluzbenaNapomenaProcjena: 'Neslužbena napomena procjene - vidljiva samo interno',
            napomenaUzArtikl: 'Napomena uz artikl'
        },
        radniNalog: {
            noviRadniNalog: 'Novi radni nalog',
            klijentovPredmet: 'Klijentov predmet',
            artikliZaNaplatu: 'Artikli za naplatu',
            cijenaUsluge: 'Cijena usluge',
            jedinicnaVrijednost: 'Jedinična vrijednost',
            vrijednost: 'Vrijednost',
            dodajKlijentovPredmet: 'Dodaj klijentov predmet',
            dodajArtikleZaNaplatu: 'Dodaj artikle za naplatu',
            ocekivaniDatumZavrsetka: 'Očekivani datum završetka',
            sluzbenaNapomenaRadnogNaloga: 'Službena napomena radnog naloga - vidljiva na radnom nalogu',
            nesluzbenaNapomenaRadnogNaloga: 'Neslužbena napomena radnog naloga - vidljiva samo interno',
            potvrdaRadnogNaloga: 'Potvrda radnog naloga',
            pitanjePotvrdeRadnogNaloga: 'Jeste li sigurni da želite napraviti ovaj radni nalog?',
            napraviRadniNalog: 'Napravi radni nalog',
            ucitavanjeSlika: 'Učitavanje slika klijentovog predmeta'
        },
        prodaja: {
            noviRacun: 'Novi račun',
            noviPredracun: 'Novi predračun',
            predracun: 'Predračun',
            nacinPlacanja: 'Način plaćanja',
            gotovinskiRacun: 'Gotovinski račun',
            karticniRacun: 'Kartični račun',
            transakcijskiRacun: 'Transakcijski račun',
            dualniRacun: 'Dualni račun',
            privatnaOsoba: 'Privatna osoba',
            fizickaOsoba: 'Fizička osoba',
            pravnaOsoba: 'Pravna osoba',
            gotovina: 'Gotovina',
            kartice: 'Kartice',
            kartica: 'Kartica',
            odaberiKarticu: 'Odaberi karticu...',
            brojRata: 'Broj rata',
            odaberiBrojRata: 'Odaberi broj rata...',
            jednokratno: 'Jednokratno',
            maksimalnaKolicina: 'Max. količina',
            datumIstekaPredracuna: 'Datum isteka predračuna',
            dualniRacunPitanje: 'Za dualni račun potrebno je odrediti koliko od ukupne cijene će biti plaćeno karticom:',
            cijenaNaKarticu: 'Cijena na karticu',
            napraviRacun: 'Napravi račun',
            napraviPredracun: 'Napravi predračun',
            potvrdaRacuna: 'Potvrda računa',
            potvrdaPredracuna: 'Potvrda predračuna',
            sluzbenaNapomenaRacuna: 'Službena napomena računa - vidljiva na računu',
            nesluzbenaNapomenaRacuna: 'Neslužbena napomena računa - vidljiva samo interno',
            sluzbenaNapomenaPredracuna: 'Službena napomena predračuna - vidljiva na predračunu',
            nesluzbenaNapomenaPredracuna: 'Neslužbena napomena predračuna - vidljiva samo interno',
            pitanjePotvrdeRacuna: 'Jeste li sigurni da želite napraviti ovaj račun?',
            pitanjePotvrdePredracuna: 'Jeste li sigurni da želite napraviti ovaj predračun?',
            obveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - unesena cijena artikla različita od definirane prodajne cijene!',
            napomenaInvesticijskoZlato: 'Pri prodaji investicijskog zlata obvezno unijeti klijenta.'
        },
        meduskladisnica: {
            novaMeduskladisnica: 'Nova međuskladišnica',
            ulaznaMeduskladisnica: 'Ulazna međuskladišnica',
            artikliSaSkladista: 'Artikli sa skladišta',
            polaziste: 'Polazište artikla',
            odrediste: 'Odredište artikla',
            maksimalnaKolicina: 'Max. količina',
            sluzbenaNapomenaMeduskladisnice: 'Službena napomena međuskladišnice - vidljiva na međuskladišnici',
            nesluzbenaNapomenaMeduskladisnice: 'Neslužbena napomena međuskladišnice - vidljiva samo interno',
            napraviMeduskladisnicu: 'Napravi međuskladišnicu',
            potvrdaMeduskladisnice: 'Potvrda međuskladišnice',
            pitanjePotvrdeMeduskladisnice: 'Jeste li sigurni da želite napraviti ovu međuskladišnicu?'
        },
        dokumenti: {
            klijenti: 'Klijenti',
            artikli: 'Artikli',
            otkupi: 'Otkupi',
            rezervacije: 'Rezervacije',
            zahtjeviZaProcjenu: 'Zahtjevi za procjenu',
            radniNalozi: 'Radni nalozi',
            racuni: 'Računi',
            predracuni: 'Predračuni',
            primke: 'Primke',
            meduskladisnice: 'Međuskladišnice',
            otkupniBlokovi: 'Otkupni blokovi',
            stanjeSkladista: 'Stanje skladišta',
            usluge: 'Usluge',
            blagajnickiDnevnikOtkup: 'Blagajnički dnevnik - otkup',
            blagajnickiDnevnikFisk: 'Blagajnički dnevnik - fiskalna blagajna',
            dnevneRadnje: 'Dnevne radnje',
            pregledTroskova: 'Pregled troškova',
            cijeneIoglasavanje: 'Cijene i oglašavanje',
            maknutiSOglasavanja: 'Lista artikala za maknuti s oglašavanja',
            izdatnice: 'Izdatnice',
            rekapitulacije: 'Rekapitulacije',
            pregledBankovnihTransakcija: 'Pregled bankovnih transakcija'
        },
        klijenti: {
            klijenti: 'Klijenti',
            noviKlijent: 'Novi klijent',
            sviKlijenti: 'Svi klijenti',
            fizickeOsobe: 'Fizičke osobe',
            pravneOsobe: 'Pravne osobe',
            klijentiSNapomenomOpreza: 'Klijenti s napomenom opreza',
            nemaKlijenata: 'U sustavu nema pohranjenih klijenata!'
        },
        artikli: {
            artikli: 'Artikli',
            noviArtikl: 'Novi artikl',
            nemaArtikala: 'U sustavu nema pohranjenih artikala!'
        },
        usluge: {
            usluge: 'Usluge',
            novaUsluga: 'Nova usluga',
            nemaUsluga: 'U sustavu nema pohranjenih usluga!'
        },
        otkupi: {
            otkupi: 'Otkupi',
            noviOtkup: 'Novi otkup',
            ispisZadnjegOtkupnogBloka: 'Ispis zadnjeg Otkupnog bloka',
            brojUgovoraOtkupa: 'Broj ugovora otkupa',
            datumIVrijemeUgovoraOtkupa: 'Datum i vrijeme ugovora otkupa',
            djelatnik: 'Djelatnik',
            klijent: 'Klijent',
            artikli: 'Artikli',
            stornoOtkupa: 'STORNO OTKUPA',
            ispisUgovoraOtkupa: 'Ispis Ugovora otkupa',
            ispisOtkupnogBloka: 'Ispis Otkupnog bloka',
            storniranjeOtkupa: 'Storniranje otkupa',
            storniranjePitanjePotvrde: 'Jeste li sigurni da želite stornirati ovaj otkup?',
            storniraj: 'Storniraj',
            urediOtkupniBlok: 'Uredi otkupni blok',
            uredivanjeOtkupnogBloka: 'Uređivanje otkupnog bloka',
            nemaUgovoraOtkupa: 'U sustavu nema pohranjenih ugovora otkupa!'
        },
        primke: {
            primke: 'Primke',
            novaPrimka: 'Nova primka',
            brojPrimke: 'Broj primke',
            datumIVrijemePrimke: 'Datum i vrijeme primke',
            djelatnik: 'Djelatnik',
            klijent: 'Klijent',
            artikli: 'Artikli',
            stornoPrimke: 'STORNO PRIMKE',
            ispisPrimke: 'Ispis Primke',
            storniranjePrimke: 'Storniranje primke',
            storniranjePitanjePotvrde: 'Jeste li sigurni da želite stornirati ovu primku?',
            storniraj: 'Storniraj',
            nemaPrimki: 'U sustavu nema pohranjenih primki!'
        },
        racuni: {
            racuni: 'Računi',
            noviRacun: 'Novi račun',
            sviRacuni: 'Svi računi',
            fiskalniRacuni: 'Fiskalni računi',
            transakcijskiRacuni: 'Transakcijski računi',
            gotovinskiRacuni: 'Gotovinski računi',
            karticniRacuni: 'Kartični računi',
            brojRacuna: 'Broj računa',
            datumIVrijemeRacuna: 'Datum i vrijeme računa',
            tipRacuna: 'Tip računa',
            stornoracuna: 'STORNO RAČUNA',
            klijent: 'Klijent',
            artikli: 'Artikli',
            storniranjeRacuna: 'Storniranje računa',
            ispisRacuna: 'Ispis računa',
            storniranjePitanjePotvrde: 'Jeste li sigurni da želite stornirati ovaj račun?',
            storniraj: 'Storniraj',
            nemaRacuna: 'U sustavu nema pohranjenih računa!'
        },
        predracuni: {
            predracuni: 'Predračuni',
            noviPredracun: 'Novi predračun',
            brojPredracuna: 'Broj predračuna',
            datumIVrijemePredracuna: 'Datum i vrijeme predračuna',
            datumIstekaPredracuna: 'Datum isteka predračuna',
            klijent: 'Klijent',
            artikli: 'Artikli',
            arhiviranjePredracuna: 'Arhiviranje predračuna',
            ispisPredracuna: 'Ispis predračuna',
            izradaRacunaIzPredracuna: 'Izrada računa iz predračuna',
            arhiviranjePitanjePotvrde: 'Jeste li sigurni da želite arhivirati ovaj predračun?',
            arhiviraj: 'Arhiviraj',
            izradaRacunaPitanjePotvrde: 'Jeste li sigurni da želite napraviti račun na temelju ovog predračuna?',
            izradi: 'Izradi',
            nemaPredracuna: 'U sustavu nema pohranjenih predračuna!'
        },
        radniNalozi: {
            radniNalozi: 'Radni nalozi',
            noviRadniNalog: 'Novi radni nalog',
            brojRadnogNaloga: 'Broj radnog naloga',
            datumIVrijemeRadnogNaloga: 'Datum i vrijeme radnog naloga',
            ocekivaniDatumZavrsetka: 'Očekivani datum završetka',
            klijent: 'Klijent',
            usluge: 'Usluge',
            klijentovPredmet: 'Klijentov predmet',
            artikliZaNaplatu: 'Artikli za naplatu',
            arhiviranjeRadnogNaloga: 'Arhiviranje radnog naloga',
            ispisRadnogNaloga: 'Ispis radnog naloga',
            arhiviranjePitanjePotvrde: 'Jeste li sigurni da želite arhivirati ovaj radni nalog?',
            arhiviraj: 'Arhiviraj',
            nemaRadnihNaloga: 'U sustavu nema pohranjenih radnih naloga!'
        },
        procjene: {
            procjene: 'Procjene',
            novaProcjena: 'Nova procjena',
            brojZahtjevaZaProcjenom: 'Broj zahtjeva za procjenom',
            datumIVrijemeZahtjevaZaProcjenom: 'Datum i vrijeme zahtjeva za procjenom',
            klijent: 'Klijent',
            artikli: 'Artikli',
            arhiviraj: 'Arhiviraj označene procjene',
            arhiviranjeProcjene: 'Arhiviranje procjene',
            ispisProcjene: 'Ispis zahtjeva za procjenom',
            nemaProcjena: 'U sustavu nema pohranjenih zahtjeva za procjenom!'
        },
        rezervacije: {
            rezervacije: 'Rezervacije',
            klijent: 'Klijent',
            artikli: 'Artikli',
            brojRezervacije: 'Broj rezervacije',
            brojArtikala: 'Broj artikala u rezervaciji',
            datumIVrijemeRezervacije: 'Datum i vrijeme rezervacije',
            rezervacijeIstekaoRZP: 'Rezervacije koje su istekle i kojima je istekao rok za podizanje',
            rezervacijeNijeIstekaoRZPIstekla: 'Rezervacije koje su istekle, ali nije istekao rok za podizanje',
            rezervacijeNijeIstekaoRZPNijeIstekla: 'Aktivne rezervacije',
            ispisPotvrdeRezervacije: 'Ispis potvrde rezervacije',
            produziRezervacije: 'Produži rezervacije',
            podigniRezervacije: 'Podigni rezervacije',
            produziRokZaPodizanje: 'Produži rok za podizanje',
            naknadaZa: 'Naknada za',
            otkaziRezervacije: 'Otkaži rezervacije',
            produzenjeRezervacije: 'Produženje rezervacije',
            podizanjeRezervacije: 'Podizanje rezervacije',
            produzivanjeRoka: 'Produženje roka za podizanje',
            otkazivanjeRezervacije: 'Otkazivanje rezervacije',
            datumIsteka: 'Datum isteka',
            rokZaPodizanje: 'Rok za podizanje',
            noviRokZaPodizanje: 'Novi rok za podizanje',
            jedinicnaCijena: 'Jedinična cijena',
            mjesecnaCijena: 'Mjesečna cijena',
            mjesecnaNaknada: 'Mjesečna naknada',
            cijenaRezervacije: 'Cijena rezervacije',
            cijenaPodizanja: 'Cijena podizanja',
            produzenjeRezervacijeRazlicitaCijenaNapomena: 'Obvezna neslužbena napomena - mjesečna cijena rezervacije različita od predefinirane!',
            produzenjeRezervacijePrekratkoProduzenjeNapomena: 'Obvezna neslužbena napomena - rezervacija će i nakon produženja još uvijek biti istekla!',
            podizanjeRezervacijeObveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - cijena rezervacije različita od predefinirane!',
            podizanjeRezervacijePremaliBrojMjesecnihNaknada:
                'Obvezna neslužbena napomena - broj mjesečnih naknada različit je od predefiniranog broja naknada!',
            produzenjeRokaRezervacijeObveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - produžuje se rok za podizanje!',
            otkazivanjeRezervacijeObveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - otkazivanje rezervacije!',
            nemaRezervacija: 'U sustavu nema pohranjenih rezervacija!'
        },
        meduskladisnice: {
            meduskladisnice: 'Međuskladišnice',
            novaMeduskladisnica: 'Nova međuskladišnica',
            brojMeduskladisnice: 'Broj međuskladišnice',
            polaziste: 'Polazište artikla',
            odrediste: 'Odredište artikla',
            izvrsena: 'Izvršena',
            datumIVrijeme: 'Datum i vrijeme međuskladišnice',
            artikliSaSkladista: 'Artikli sa skladišta',
            ispisMeduskladisnice: 'Ispis međuskladišnice',
            stornoMeduskladisnice: 'STORNO MEĐUSKLADIŠNICE',
            storniranjeMeduskladisnice: 'Storniranje međuskladišnice',
            storniranjePitanjePotvrde: 'Jeste li sigurni da želite stornirati ovu međuskladišnicu?',
            storniraj: 'Storniraj',
            izvrsiMeduskladisnicu: 'Izvrši međuskladišnicu',
            izvrsavanjeMeduskladisnice: 'Izvršavanje međuskladišnice',
            izvrsavanjeMeduskladisnicePitanjePotvrde: 'Jeste li sigurni da želite izvršiti ovu međuskladišnicu?',
            nemaMeduskladisnica: 'U sustavu nema pohranjenih međuskladišnica!'
        },
        izdatnice: {
            izdatnice: 'Izdatnice',
            novaIzdatnica: 'Nova izdatnica',
            potvrdaIzdatnice: 'Potvrda izdatnice',
            napraviIzdatnicu: 'Napravi izdatnicu',
            brojIzdatnice: 'Broj izdatnice',
            datumIzdatnice: 'Datum izdatnice',
            stornoIzdatnice: 'STORNO IZDATNICE',
            artikli: 'Artikli',
            storniranjeIzdatnice: 'Storniranje izdatnice',
            ispisIzdatnice: 'Ispis izdatnice',
            maksimalnaKolicina: 'Max. količina',
            jedinicnaNabavnaCijena: 'Jedinična nabavna cijena',
            nabavnaCijena: 'Nabavna cijena',
            storniranjePitanjePotvrde: 'Jeste li sigurni da želite stornirati ovu izdatnicu?',
            storniraj: 'Storniraj',       
            pitanjePotvrdeIzdatnice: 'Jeste li sigurni da želite napraviti ovu Izdatnicu?',
            nemaIzdatnice: 'U sustavu nema pohranjenih izdatnica!',
            sluzbenaNapomenaIzdatnice: 'Službena napomena izdatnice - vidljiva na izdatnici',
            nesluzbenaNapomenaIzdatnice: 'Neslužbena napomena izdatnice - vidljiva samo interno',
            poslovnica: 'Poslovnica',
        },
        stanjeSkladista: {
            stanjeSkladista: 'Stanje skladišta',
            akcijeStanjaSkladista: 'Akcije stanja skladišta',
            unosProdajnihCijena: 'Unos prodajnih cijena',
            promjenaProdajnihCijena: 'Promjena prodajnih cijena',
            printZaInventuru: 'Print za inventuru',
            printZaKnjigovodstvo: 'Print za knjigovodstvo',
            printStanjaSkladistaNaOdredeniDan: 'Print stanja skladišta na određeni dan',
            printTrenutnogStanjaSkladistaZaInventuru: 'Print trenutnog stanja skladišta za inventuru',
            printTrenutnogStanjaSkladistaZaKnjigovodstvo: 'Print trenutnog stanja skladišta za knjigovodstvo',
            printanjeZaKnjigovodstvoPitanjePotvrde: 'Jeste li sigurni da želite isprintati stanje skladišta za knjigovodstvo?',
            odabirSkladistaZaPrintanjeStanjaSkladista: 'Odaberite skladište za koje želite isprintati stanje skladišta:',
            odabirDatumaZaPrintanjeStanjaSkladista: 'Odaberite datum za koji želite isprintati stanje skladišta:',
            ukupnaVrijednostSkladista: 'Ukupna vrijednost skladišta',
            kolicinaSlobodnihKolicinaUkupno: 'Količina slobodnih / ukupna količina',
            jedinicnaCijenaOtkupa: 'Jedinična cijena otkupa',
            jedinicnaProdajnaCijena: 'Jedinična prodajna cijena',
            predlozenaProdajnaCijena: 'Predložena prodajna cijena',
            potvrdiProdajneCijene: 'Potvrdi prodajne cijene',
            napomeneZaArtikl: 'Napomene za artikl',
            nemaArtikalaNaSkladistu: 'U sustavu nema artikala na stanju skladišta!'
        },
        cijeneOglasavanje: {
            artikliNaSkladistu: 'Artikli na skladištu'
        },
        maknutiSOglasavanja: {
            artikliKojeJePotrebnoMaknutiSOglasavanja: 'Artikli koje je potrebno maknuti s oglašavanja',
            potvrdaMicanja: 'Potvrda micanja',
            pitanjePotvrde: 'Jeste li sigurni da ste maknuli artikl sa svih oglasnika?',
            nemaArtikalaZaMicanjeSOglasavanja: 'U sustavu nema artikala koje je potrebno maknuti s oglašavanja'
        },
        blagajne: {
            stanjeFiskalneBlagajne: 'Stanje fiskalne blagajne',
            stanjeBlagajneOtkupa: 'Stanje blagajne otkupa',
            uplateIsplateTroskovi: 'Uplate, isplate, troškovi',
            unosBankovnogTroska: 'Unos bankovnog troška',
            unosTroska: 'Unos troška',
            interniPrijenos: 'Interni prijenos',
            napraviUplatnicuIsplatnicu: 'Napravi uplatnicu/isplatnicu',
            poslovnica: 'Poslovnica',
            uFiskalnuBlagajnu: 'u fiskalnu blagajnu',
            uBlagajnuOtkupa: 'u blagajnu otkupa',
            izFiskalneBlagajne: 'iz fiskalne blagajne',
            izBlagajneOtkupa: 'iz blagajne otkupa',
            uplatnica: 'Uplatnica',
            isplatnica: 'Isplatnica',
            iznos: 'Iznos',
            pozitivniTrosak: 'Pozitivni trošak',
            uplatnicaObveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - uplata/isplata iz blagajne!',
            interniPrijenosObveznaNesluzbenaNapomena: 'Obvezna neslužbena napomena - interni prijenos sredstava!',
            napraviUplatu: 'Napravi uplatu',
            napraviIsplatu: 'Napravi isplatu',
            napraviPrijenos: 'Napravi prijenos',
            jednokratniTrosak: 'Jednokratni trošak',
            grupeOneTimeTroskova: 'Grupa jednokratnog troška',
            godina: 'Godina',
            trosak: 'Trošak',
            dan: 'Dan',
            mjesec: 'Mjesec',
            nazivTroska: 'Naziv troška',
            unesiTrosak: 'Unesi trošak'
        },
        dnevnaEvidencija: {
            dnevneRadnje: 'Dnevne radnje',
            filtrirajKorisnika: 'Filtriraj korisnika',
            filtriraj: 'Filtriraj',
            svi: 'Svi',
            korisnici: 'korisnici',
            datumIVrijemeRadnje: 'Datum i vrijeme radnje',
            radnja: 'Radnja',
            korisnik: 'Korisnik',
            kreiranaMeduskladisnica: 'Kreirana MEĐUSKLADIŠNICA',
            izvrsenaMeduskladisnica: 'Izvršena MEĐUSKLADIŠNICA',
            potvrdenaProdajnaCijena: 'Potvrđena PRODAJNA cijena',
            predlozenaProdajnaCijena: 'Predložena PRODAJNA cijena',
            promjenjenaProdajnaCijena: 'Promjenjena PRODAJNA cijena',
            kreiranPredracun: 'Kreiran PREDRAČUN',
            arhiviranPredracun: 'Arhiviran PREDRAČUN',
            izradenRacun: 'Izrađen RAČUN',
            izPredracuna: 'iz PREDRAČUNA',
            kreiranaProcjena: 'Kreiran ZAHTJEV ZA PROCJENOM',
            arhiviranaProcjena: 'Arhivirana PROCJENA iz ZAHTJEVA ZA PROCJENOM',
            kreiranRacun: 'Kreiran RAČUN',
            storniranRacun: 'Storniran RAČUN',
            kreiranRadniNalog: 'Kreiran RADNI NALOG',
            arhiviranRadniNalog: 'Arhiviran RADNI NALOG',
            kreiranaRezervacija: 'Kreirana REZERVACIJA',
            produzenaRezervacija: 'Produzena REZERVACIJA',
            podignutaRezervacija: 'Podignuta REZERVACIJA',
            otkazanaRezervacija: 'Otkazana REZERVACIJA',
            produzenRokRezervacije: 'Produžen rok REZERVACIJE',
            kreiranOtkup: 'Kreiran OTKUP',
            storniranOtkup: 'Storniran OTKUP',
            isprintanOtkupniBlok: 'Isprintan OTKUPNI BLOK',
            kreiranaPrimka: 'Kreirana PRIMKA',
            storniranaPrimka: 'Stornirana PRIMKA',
            promjenjenaNabavnaCijena: 'Promjenjena NABAVNA cijena',
            napravljenaUplata: 'Napravljena UPLATA',
            napravljenaIsplata: 'Napravljena ISPLATA',
            fiskalna: 'FISKALNA',
            otkupna: 'OTKUPNA',
            blagajna: 'BLAGAJNA',
            uIznosuOd: 'u iznosu od',
            uplatnice: 'uplatnice',
            isplatnice: 'isplatnice',
            prijavaKorisnika: 'PRIJAVA korisnika',
            interniPrijenos: 'INTERNI PRIJENOS',
            artiklKojemJePromijenjenaCijena: 'Artikl kojem je promijenjena cijena',
            staraCijena: 'Stara cijena',
            novaCijena: 'Nova cijena',
            otkup: 'Otkup',
            primka: 'Primka',
            storniraniOtkup: 'Stornirani otkup',
            storniranaPrimkaMalo: 'Stornirana primka',
            racun: 'Račun',
            predracun: 'Predračun',
            storniraniRacun: 'Stornirani račun',
            zahtjevZaProcjenom: 'Zahtjev za procjenom',
            arhiviraneProcjene: 'Arhivirane procjene',
            produzeneRezervacije: 'Produžene rezervacije',
            otkupniBlokUzRezervaciju: 'Otkupni blok uz rezervaciju',
            podignuteRezervacije: 'Podignute rezervacije',
            otkazaneRezervacije: 'Otkazane rezervacije',
            rezervacijeKojimaProduzenRok: 'Rezervacije kojima je produžen rok za podizanje'
        },
        blagajnickiDnevnici: {
            blagajnickiDnevnikFisk: 'Blagajnički dnevnik FISKALNA BLAGAJNA',
            blagajnickiDnevnikOtkup: 'Blagajnički dnevnik BLAGAJNA OTKUPA',
            filtriraj: 'Filtriraj',
            datumIVrijeme: 'Datum i vrijeme',
            stavkaBlagajne: 'Stavka blagajne',
            uplata: 'Uplata',
            isplata: 'Isplata',
            otkup: 'OTKUP',
            primka: 'PRIMKA',
            fiskalniRacun: 'FISKALNI RAČUN',
            transakcijskiRacun: 'TRANSAKCIJSKI RAČUN',
            napravljenaUplata: 'Napravljena UPLATA',
            napravljenaIsplata: 'Napravljena ISPLATA',
            interniPrijenos: 'INTERNI PRIJENOS',
            fiskalna: 'FISKALNA',
            otkupna: 'OTKUPNA',
            blagajna: 'BLAGAJNA',
            pocetnoStanje: 'POČETNO STANJE',
            zavrsnoStanje: 'ZAVRŠNO STANJE',
            poslovnica: 'Poslovnica',
            ukupnoUplate: 'Ukupno uplate',
            ukupnoIsplate: 'Ukupno isplate'
        },
        pregledTroskova: {
            pregledTroskova: 'Pregled troškova',
            poslovnica: 'Poslovnica',
            mjesec: 'Mjesec',
            godina: 'Godina',
            filtriraj: 'Filtriraj',
            tipTroska: 'Tip troška',
            nazivTroska: 'Naziv troška',
            grupaTroska: 'Grupa troška',
            iznos: 'Iznos',
            datumIVrijeme: 'Datum i vrijeme troška',
            korisnik: 'Korisnik',
            uplatnickiJednokratniTrosak: 'Uplatnički jednokratni trošak',
            bankovniJednokratniTrosak: 'Bankovni jednokratni trošak',
            placenUplatnickiPonavljajuciTrosak: 'Plaćen uplatnički ponavljajući trošak',
            placenBankovniPonavljajuciTrosak: 'Plaćen bankovni ponavljajući trošak',
            ponavljajuciTrosakNaCekanju: 'Ponavljajući trošak na čekanju',
            otkazanPonavljajuciTrosak: 'Otkazan ponavljajući trošak',
            detaljiTroska: 'Detalji troška',
            jednokratniTrosak: 'Jednokratni trošak',
            ponavljajuciTrosak: 'Ponavljajući trošak'
        },
        rekapitulacije: {
            rekapitulacije: 'Rekapitulacije',
            tipRekapitulacije: 'Tip rekapitulacije',
            fiskalniRacuni: 'Fiskalni računi',
            transakcijskiRacuni: 'Transakcijski računi',
            otkupniBlokovi: 'Otkupni blokovi',
            blagajnickiDnevnikFisk: 'Blagajnički dnevnik - fiskalna blagajna',
            blagajnickiDnevnikBlagajnaOtkupa: 'Blagajnički dnevnik - blagajna otkupa',
            zaPoslovnicu: 'za poslovnicu',
            zaMjesec: 'za mjesec',
            uGodini: 'u godini',
            napraviRekapitulaciju: 'Napravi rekapitulaciju',
            rekapitulacijaRacuna: {
                rekapitulacijaFiskalnihRacuna: 'Rekapitulacija FISKALNIH računa',
                rekapitulacijaTransakcijskihRacuna: 'Rekapitulacija TRANSAKCIJSKIH računa',
                brojRacuna: 'Broj računa',
                datumIzdavanja: 'Datum izdavanja',
                klijent: 'Klijent',
                oib: 'OIB',
                privatnaOsoba: 'Privatna osoba',
                nacinPlacanja: 'Način plaćanja',
                vrstaRacuna: 'Vrsta računa',
                gotovina: 'Gotovina',
                kartica: 'Kartica',
                fiskalniRacun: 'Fiskalni račun',
                transakcijskiRacun: 'Transakcijski račun',
                iznosRabljeneRobe: 'Iznos rabljene robe',
                iznosNoveRobe: 'Iznos nove robe',
                iznosInvZlata: 'Iznos inv. zlata',
                iznosUsluga: 'Iznos usluga',
                nabavnaVrijednost: 'Nabavna vrijednost',
                fakturnaVrijednost: 'Fakturna vrijednost',
                iznosRabata: 'Iznos rabata',
                iznosMarze: 'Iznos marže',
                vrijednostBezPoreza: 'Vrijednost bez poreza',
                osnovicaPoreza: 'Osnovica poreza',
                iznosPoreza: 'Iznos poreza',
                vrijednostSPorezom: 'Vrijednost s porezom',
                sumaPoNacinuPlacanja: 'Suma po načinu plaćanja'
            },
            rekapitulacijaOtkupnihBlokova: {
                rekapitulacijaOtkupnihBlokova: 'Rekapitulacija OTKUPNIH BLOKOVA',
                brojOtkupnogBloka: 'Broj otkupnog bloka',
                datumDokumenta: 'Datum dokumenta',
                klijent: 'Klijent',
                oib: 'OIB',
                nazivArtikla: 'Naziv artikla',
                sifraArtikla: 'Šifra artikla',
                kolicina: 'Količina',
                nabavnaVrijednost: 'Nabavna vrijednost'
            }
        },
        pregledBankovnihTransakcija: {
            pregledBankovnihTransakcija: 'Pregled bankovnih transakcija',
            poslovnica: 'Poslovnica',
            mjesec: 'Mjesec',
            godina: 'Godina',
            filtriraj: 'Filtriraj',
            datumIVrijemeDokumenta: 'Datum i vrijeme dokumenta',
            tipTransakcije: 'Tip transakcije',
            uplaceniIsplaceniIznos: 'Uplaćeni/isplaćeni iznos',
            izradio: 'Izradio',
            potvrdena: 'Potvrđena',
            potvrdi: 'Potvrdi',
            potvrdaHeader: 'Potvrda bankovne transakcije',
            pitanjePotvrde: 'Jeste li sigurni da želite potvrditi bankovnu transakciju?',
            uplataZaFiskalniRacun: 'Uplata za FISKALNI račun',
            uplataZaTransakcijskiRacun: 'Uplata za TRANSAKCIJSKI račun',
            uplataZaPrimku: 'Uplata za primku',
            bankovnaIsplataJednokratnogTroska: 'Bankovna isplata jednokratnog troška',
            bankovnaIsplataPonavljajucegTroska: 'Bankovna isplata ponavljajućeg troška'
        },
        kontrola: {
            kontrola: 'Kontrola',
            povratak: 'Povratak',
            poslovnica: 'Poslovnica',
            mjesec: 'Mjesec',
            godina: 'Godina',
            filtriraj: 'Filtriraj',
            datumKontrole: 'Datum kontrole',
            brojNetocnihUnosa: 'Broj netočnih unosa',
            unosKontrole: 'Unos kontrole',
            pregledUnosaKontrole: 'Pregled unosa kontrole',
            tocanIznos: 'Točan iznos',
            postotakPopunjenosti: 'Postotak popunjenosti',
            datumIVrijemeRadnje: 'Datum i vrijeme radnje',
            radnja: 'Radnja',
            napomenaKontrole: 'Napomena kontrole'
        },
        postavke: {
            postavke: 'Postavke',
            upravljanjeFirmom: 'Upravljanje firmom',
            opceInformacije: 'Opće informacije',
            imeFirme: 'Ime firme',
            oibFirme: 'OIB firme',
            oFirmi: 'Informacije o firmi - MBS trgovačkog suda, temeljni kapital, uprava društva. (bit će vidljivo na računu)',
            adresaSjedista: 'Adresa sjedišta',
            postanskiBrojSjedista: 'Poštanski broj sjedišta',
            naseljeSjedista: 'Naselje sjedišta',
            brojPoslovnica: 'Broj poslovnica',
            maksimalniPolog: 'Maksimalni polog u svim poslovnicama',
            iznosFiskalneBlagajne: 'Iznos novca na koje će se fiksirati fiskalna blagajna',
            uSustavuPDVa: 'Firma je u sustavu PDV-a',
            poslovnice: 'Poslovnice',
            skladista: 'Skladišta',
            nazivSkladista: 'Naziv',
            adresaSkladista: 'Adresa',
            mjestoSkladista: 'Mjesto',
            brisanjePoslovnicePotvrda: 'Jeste li sigurni da želite obrisati poslovnicu?',
            brisanjeSkladistaPotvrda: 'Jeste li sigurni da želite obrisati skladište?',
            pocetnoStanjeFiskBlag: 'Početno stanje fiskalne blagajne',
            pocetnoStanjeBlagOtkupa: 'Početno stanje blagajne otkupa',
            radnoVrijeme: 'Radno vrijeme',
            pon: 'Pon',
            uto: 'Uto',
            sri: 'Sri',
            cet: 'Čet',
            pet: 'Pet',
            sub: 'Sub',
            ned: 'Ned',
            brojPoslovnice: 'Broj poslovnice',
            dodajNovuPoslovnicu: 'Dodaj novu poslovnicu',
            dodajNovoSkladiste: 'Dodaj novo skladište',
            upravljanjeKorisnicima: 'Upravljanje korisnicima',
            korisnici: 'Korisnici',
            brisanjeKorisnikaPotvrda: 'Jeste li sigurni da želite obrisati korisnika?',
            tipKorisnika: 'Tip korisnika',
            korisnickoIme: 'Korisničko ime',
            brojDjelatnika: 'Broj djelatnika',
            imeKorisnika: 'Ime',
            prezimeKorisnika: 'Prezime',
            emailKorisnika: 'E-mail',
            oibKorisnika: 'OIB',
            resetLozinke: 'Reset lozinke',
            resetLozinkePotvrda: 'Jeste li sigurni da želite resetirati lozinku korisnika?',
            dodajNovogKorisnika: 'Dodaj novog korisnika',
            upravljanjePostotcima: 'Upravljanje postotcima',
            postotciRezervacija: 'Postotci rezervacija',
            tipCijeneRezervacije: 'Tip cijene rezervacije',
            donjaGrCijeneArtikla: 'Donja granica ukupne vrijednosti rezervacije',
            vrijednostRezervacije: 'Cijena rezervacije',
            dodajNovuCijenuRezervacije: 'Dodaj novu cijenu rezervacije',
            ostaliPostotci: 'Ostali postotci',
            postotakSmanjivanjaOdProdajneCijene: 'Postotak smanjivanja od prodajne cijene',
            postotakZaKarticnoPlacanje: 'Postotak za kartično plaćanje',
            brisanjeKarticePotvrda: 'Jeste li sigurni da želite obrisati karticu?',
            kartice: 'Kartice',
            nazivKartice: 'Naziv kartice',
            karticniUvjeti: 'Kartični uvjeti',
            dodajNovuKarticu: 'Dodaj novu karticu',
            dodajNoveKarticneUvjete: 'Dodaj nove kartične uvjete',
            pocetnoMjeseci: 'Donja granica broja mjeseci za ove kartične uvjete',
            postotakKartice: 'Postotak',
            odgodaUplate: 'Broj dana odgode uplate',
            mjeseciPlaceholder: 'mjeseci',
            danaPlaceholder: 'dana',
            kategorije: 'Kategorije',
            dodajNovuKategoriju: 'Dodaj novu kategoriju',
            dodajNovuPotkategoriju: 'Dodaj novu potkategoriju',
            brisanjeKategorijePotvrda: 'Jeste li sigurni da želite obrisati kategoriju?',
            imeKategorije: 'Ime kategorije',
            tipKategorije: 'Tip kategorije',
            potkategorije: 'Potkategorije',
            imePotkategorije: 'Ime potkategorije',
            mjerneJedinice: 'Mjerne jedinice',
            dodajNovuMjernuJedinicu: 'Dodaj novu mjernu jedinicu',
            brisanjeMjerneJedinicePotvrda: 'Jeste li sigurni da želite obrisati mjernu jedinicu?',
            nazivMjerneJedinice: 'Naziv mjerne jedinice',
            mjernaJedinicaJeCjelobrojna: 'Mjerna jedinica je cjelobrojna.',
            porezniZakoni: 'Porezni zakoni',
            brisanjePoreznihZakonaPotvrda: 'Jeste li sigurni da želite obrisati porezni zakon?',
            tipPoreza: 'Tip poreza',
            tekstPoreza: 'Tekst poreza',
            postotakPoreza: 'Postotak',
            noviPredmetPorez: 'Porez na nove predmete',
            rabljeniPredmetPorez: 'Porez na rabljene predmete',
            investicijskoZlatoPorez: 'Porez na investicijsko zlato',
            uslugaPorez: 'Porez na usluge',
            dodajPorezniZakon: 'Dodaj porezni zakon',
            cijeneIoglasavanje: 'Cijene i oglašavanje',
            oznake: 'Oznake',
            dodajNovuOznaku: 'Dodaj novu oznaku',
            brisanjeOznakePotvrda: 'Jeste li sigurni da želite obrisati oznaku?',
            nazivOznake: 'Naziv oznake',
            ponavljajuciTroskovi: 'Ponavljajući troškovi',
            dodajNoviPonavljajuciTrosak: 'Dodaj novi ponavljajući trošak',
            brisanjePonavljajucegTroskaPotvrda: 'Jeste li sigurni da želite obrisati ponavljajući trošak?',
            tipTroska: 'Tip troška',
            dnevniTrosak: 'Dnevni trošak',
            mjesecniTrosak: 'Mjesečni trošak',
            godisniTrosak: 'Godišnji trošak',
            nazivTroska: 'Naziv troška',
            trosakZaPoslovnicu: 'Trošak za poslovnicu',
            iznosTroska: 'Iznos troška',
            deaktivirajTrosak: 'Deaktiviraj trošak',
            aktivirajTrosak: 'Aktiviraj trošak',
            grupeJednokratnihTroskova: 'Grupe jednokratnih troškova',
            dodajNovuGrupuJednokratnogTroska: 'Dodaj novu grupu jednokratnog troška',
            brisanjeGrupeJednokratnogTroska: 'Jeste li sigurni da želite obrisati grupu jednokratnog troška?',
            nazivGrupeJednokratnogTroska: 'Naziv grupe jednokratnog troška'
        },
        printingComponents: {
            potvrdaRezervacije: {
                naslov: 'POTVRDA REZERVACIJE',
                podnaslov: 'Ovom potvrdom %firma% potvrđuje rezervaciju sljedećih artikala:',
                izjavaOsobnaIskaznica: 'Predmet s rezervacije se može podići isključivo uz predočenje osobne iskaznice, uz prethodnu najavu 24 sata ranije.',
                izjavaDvaPrimjerka: 'Ova %naslov% je sačinjena u dva istovjetna primjerka od kojih jedan ide klijentu, a drugi %firma%',
                izjavaDogovor: 'Ukoliko u dogovorenom roku klijent ne kupi artikl, ova %naslov% se smatra nevažećom!'
            },
            ugovorOtkupa: {
                naslov: 'UGOVOR OTKUPA',
                izjavaProdavatelja: 'Izjava prodavatelja',
                suglasnost:
                    'Suglasan sam da %firma% ne odgovara za dokumente, podatke, informacije, autorska i vlasnička prava trećih osoba te bilo koji drugi sadržaj, kao ni moguću štetu prema prodavatelju ili bilo kojoj trećoj strani koja bi mogla nastati zbog upotrebe istih.',
                garancija:
                    'Svojim potpisom garantirate da ste jedini vlasnik predmeta koji ste dali na otkup, te da ste poslovno sposobni, da predmet nije opterećen potraživanjima trećih, niti ste u njegovo posjedstvo stekli direktno ili indirektno došli kriminalnim radnjama. Kod eventualnog krivotvorenja ili davanja krivih podataka odgovarate osobno!'
            },
            primka: {
                naslov: 'PRIMKA'
            },
            otkupniBlok: {
                naslov: 'OTKUPNI BLOK ZA OTKUP RABLJENOG DOBRA'
            },
            racun: {
                naslov: 'RAČUN',
                gotovina: 'GOTOVINA',
                kartica: 'KARTICA',
                gotovinaIkartica: 'GOTOVINA/KARTICA',
                transakcijskiRacun: 'TRANSAKCIJSKI RAČUN',
                ukupnoRabljeniPredmeti: 'Ukupno rabljena dobra',
                ukupnoBezPDVa: 'Ukupno bez PDV',
                ukupnoBezPDVaIPopusta: 'Ukupno bez PDV - popust',
                izjavaVidenoKupljeno: 'Roba je kupljena po principu viđeno-kupljeno, te je kupac sam dužan utvrditi ispravnost proizvoda prije kupnje.',
                dodatnoPojasnjenjePDVa:
                    'PDV na računu nije iskazan za artikle investicijskog zlata zbog oslobođenja te za rabljena dobra zbog posebnog postupka oporezivanja marže.',
                napomenaTransakcijskiRacun: 'U slučaju neplaćanja, ovaj račun može poslužiti kao vjerodostojna isprava za određivanje ovrhe.'
            },
            izdatnica: {
                naslov: 'IZDANICA',
            },
            zapisnikOProdajiIspodNabavneCijene: {
                naslov: 'ZAPISNIK O PRODAJI PREDMETA ISPOD NABAVNE VRIJEDNOSTI',
                izjava: 'Izjavljujem da smo prodali predmet',
                podSifromArtikla: 'pod šifrom arikla',
                ispodNabavneVrijednosti: 'ispod nabavne vrijednosti',
                objasnjenje:
                    'Tržišna vrijednost takvog uređaja je pala, te smo bili primorani prodati predmet ispod nabavne vrijednosti, da smanjimo gubitak, koji je mogao biti i veći.'
            },
            predracun: {
                naslov: 'PREDRAČUN'
            },
            zahtjevZaProcjenom: {
                naslov: 'ZAHTJEV ZA PROCJENOM PREDMETA',
                molba:
                    'Molim tvrtku %firma% da u svrhu mogućeg otkupa predmeta u mom vlasništvu, izvrši potrebna ispitivanja (mehanička, termička, kemijska ili ...) kako bi utvrdila kakvoću predmeta koje nudim.',
                prihvacanje:
                    'Prihvaćam činjenicu da tvrtka %firma% nije dužna po izvršenim provjerama ili procjeni izvršiti otkup predmeta koje nudim i na kojima se radila procjena.',
                nazivPredmetaZaIspitivanje: 'Naziv predmeta za koji se traži ispitivanje/procjena kakvoće',
                izjavaVlasnika: 'Izjava vlasnika',
                izjavaOVlasnistvu: 'Izjavljujem da je predmet koji prodajem isključivo moje vlasništvo.',
                obveza:
                    'Kupac je dužan robu s procjene podići u roku od 30 dana. Ukoliko se roba s procjene ne podigne u roku od 30 dana, tvrtka %firma% zadržava robu, te obje strane nemaju međusobnih potraživanja, vezano uz ovu transakciju.'
            },
            radniNalog: {
                naslov: 'RADNI NALOG',
                radniNalogIzvrsio: 'Radni nalog izvršio',
                podnositeljZahtjeva: 'Podnositelj zahtjeva'
            },
            meduskladisnica: {
                naslov: 'MEĐUSKLADIŠNICA'
            },
            stanjeSkladista: {
                naslov: 'STANJE SKLADIŠTA'
            },
            stanjeSkladistaInventura: {
                naslov: 'STANJE SKLADIŠTA ZA INVENTURU'
            },
            ceduljicaSCijenom: {
                potvrdaNazivaArtikla: 'Potvrda naziva artikla za printanje',
                nazivArtikla: 'Naziv artikla',
                potvrdiNaziv: 'Potvrdi naziv',
                promijeniNaziv: 'Promijeni naziv',
                sifra: 'Šifra',
                gotovina: 'Gotovina',
                kartice: 'Kartice',
                rata: 'Rata /12 mj.'
            },
            common: {
                poslovnaJedinica: 'Poslovna jedinica',
                mjesto: 'Mjesto',
                mjestoIzdavanja: 'Mjesto izdavanja',
                datum: 'Datum',
                datumIzdavanja: 'Datum izdavanja',
                vrijemeIzdavanja: 'Vrijeme izdavanja',
                oznakaOperatera: 'Oznaka operatera',
                zki: 'ZKI',
                jir: 'JIR',
                valutaPlacanja: 'Valuta plaćanja',
                nacinPlacanja: 'Način plaćanja',
                datumOtpreme: 'Datum otpreme',
                nacinOtpreme: 'Način otpreme',
                gotovina: 'Gotovina',
                transakcijskiRacun: 'Transakcijski račun',
                privatnaOsoba: 'Privatna osoba',
                oib: 'OIB',
                brojOsobne: 'Broj osobne iskaznice',
                brojPutovnice: 'Broj putovnice',
                izradio: 'Izradio',
                mp: 'M.P.',
                kupac: 'Kupac',
                prodavatelj: 'Prodavatelj',
                djelatnik: 'Djelatnik',
                komitent: 'Komitent',
                skladiste: 'Skladište',
                br: 'br.',
                sluzbenaNapomena: 'Službena napomena',
                izjavaPorezniObveznik: 'Izjavljujem da nisam porezni obveznik po čl. 6. Zakona o porezu na dodanu vrijednost, niti sam obveznik fiskalizacije.',
                dokumentObradenElektronickimPutem: 'Dokument je obrađen elektroničkim putem i valjan je bez potpisa i pečata.',
                fiksniTecajKonverzije: 'Fiksni tečaj konverzije'
            }
        },
        common: {
            klijent: 'Klijent',
            pretraziKlijente: 'Pretraži klijente',
            unosNovogKlijenta: 'Unesi novog klijenta',
            urediKlijenta: 'Uredi klijenta',
            placeholderUnosKlijentaTitle: 'Klijent nije odabran!',
            placeholderUnosKlijentaContent: 'Pronađi klijenta ili unesi novog...',
            artikli: 'Artikli',
            predmeti: 'Predmeti',
            usluge: 'Usluge',
            pretraziArtikle: 'Pretraži artikle',
            unosNovogArtikla: 'Unesi novi artikl',
            unosNovogPredmeta: 'Unesi novi predmeta',
            urediArtikl: 'Uredi artikl',
            urediPredmet: 'Uredi predmet',
            urediUslugu: 'Uredi uslugu',
            placeholderUnosArtiklaTitle: 'Artikl nije odabran!',
            placeholderUnosArtiklaContent: 'Pronađi artikl ili unesi novi...',
            placeholderUnosPredmetTitle: 'Predmet nije odabran!',
            placeholderUnosPredmetContent: 'Pronađi predmet ili unesi novi...',
            placeholderUnosUslugeTitle: 'Usluga nije odabrana!',
            placeholderUnosUslugeContent: 'Pronađi uslugu...',
            unesenaPretragaNemaRezultata: 'Unesena pretraga nema rezulata!',
            pokusajDrugacijuPretragu: 'Pokušaj drugačiju pretragu...',
            ukupno: 'Ukupno',
            skladiste: 'Skladište',
            sluzbenaNapomena: 'Službena napomena',
            nesluzbenaNapomena: 'Neslužbena napomena',
            napomena: 'Napomena',
            napomene: 'Napomene',
            dodajNapomenu: 'Dodaj napomenu',
            ucitajSlike: 'Učitaj slike',
            pregledajSlike: 'Pregledaj slike',
            galerijaSlika: 'Galerija slika',
            spremiSlike: 'Spremi slike',
            spremiPromjene: 'Spremi promjene',
            nedovoljnoNovcaFiskalnaBlagajna: 'Nedovoljno novca u fiskalnoj blagajni. Napravi uplatu u blagajnu.',
            nedovoljnoNovcaBlagajnaOtkupa: 'Nedovoljno novca u blagajni otkupa. Napravi uplatu u blagajnu.',
            artikl: 'artikl',
            da: 'DA',
            ne: 'NE',
            za: 'za',
            mjesec: 'mjesec',
            mjeseca: 'mjeseca',
            mjeseci: 'mjeseci',
            u: 'u',
            i: 'i',
            iz: 'iz',
            hrk: 'kn',
            eur: 'eur',
            kol: 'Kol',
            br: 'br.',
            percentageSign: '%',
            vrijeme: 'Vrijeme',
            kolicina: 'Količina',
            vrijediDo: 'Vrijedi do:',
            jedinicnaCijena: 'Jedinična cijena',
            trzisnaOtkupnaVrijednost: 'Tržišna otkupna vrijednost',
            otkupnaCijena: 'Otkupna cijena',
            prodajnaCijena: 'Prodajna cijena',
            cijena: 'Cijena',
            vrijednost: 'Vrijednost',
            ukupnaCijena: 'Ukupna cijena',
            pdv: 'PDV',
            popust: 'Popust',
            iznos: 'Iznos',
            spremi: 'Spremi',
            odustani: 'Odustani',
            potvrda: 'Potvrda',
            ispis: 'Ispis',
            vratiNaSpremljeno: 'Vrati na spremljeno',
            potvrdaBrisanja: 'Potvrda brisanja',
            obrisi: 'Obriši',
            uTranzitu: 'U tranzitu - međuskladišnica',
            ok: 'OK',
            dogodilaSeGreska: 'Dogodila se greška!',
            nepoznataGreska: 'Nepoznata greška.',
            filterIsort: 'Filter i sort',
            filtriranje: 'Filtriranje',
            sortiranje: 'Sortiranje',
            filtriraj: 'Filtriraj',
            sortiraj: 'Sortiraj',
            svePoslovnice: 'Sve poslovnice',
            ucitavanje: 'Učitavanje ...',
            molimoPricekajte: 'Molimo pričekajte.',
            pokusajtePonovno: 'Pokušajte ponovno.',
        }
    }
});
